import _0 from "./assets/img/media/0.82-.jpeg";
import _1 from "./assets/img/media/0.83-.jpeg";
import _2 from "./assets/img/media/0.84-.jpeg";
import _3 from "./assets/img/media/0.85-.jpeg";
import _4 from "./assets/img/media/0.86-.jpeg";
import _5 from "./assets/img/media/0.87-.jpeg";
import _6 from "./assets/img/media/0.88-.jpeg";
import _7 from "./assets/img/media/0.89-.jpeg";
import _8 from "./assets/img/media/0.90-.jpeg";
import _9 from "./assets/img/media/0.91-.jpeg";
import _10 from "./assets/img/media/0.92-.jpeg";
import _11 from "./assets/img/media/0.93-.jpeg";
import _12 from "./assets/img/media/0.94-.jpeg";
import _13 from "./assets/img/media/0.95-.jpeg";
import _14 from "./assets/img/media/0.96-.jpeg";
import _15 from "./assets/img/media/0.97-.jpeg";
import _16 from "./assets/img/media/0.98-.jpeg";
import _17 from "./assets/img/media/0.99-.jpeg";
import _18 from "./assets/img/media/1-profil-marion.jpeg";
import _19 from "./assets/img/media/2-bio-portrait.jpeg";
import _20 from "./assets/img/media/2.5-bio-portrait.jpeg";
import _21 from "./assets/img/media/3-contact.jpeg";
import _22 from "./assets/img/media/4-hero.jpeg";
import _23 from "./assets/img/media/5-bio-banner.jpeg";
import _24 from "./assets/img/media/6-marion-nabil.jpeg";
import _25 from "./assets/img/media/7-marion-soliste.jpeg";
import _26 from "./assets/img/media/8-news.jpeg";
import _27 from "./assets/img/media/9-bonnelie.jpeg";
import _28 from "./assets/img/media/9.1-bonnelie.jpeg";
import _29 from "./assets/img/media/10-bonnelie.jpeg";
import _30 from "./assets/img/media/11-.jpeg";
import _31 from "./assets/img/media/12-.jpeg";
import _32 from "./assets/img/media/13-.jpeg";
import _33 from "./assets/img/media/14-.jpeg";
import _34 from "./assets/img/media/15-.jpeg";
import _35 from "./assets/img/media/16-.jpeg";
import _36 from "./assets/img/media/17-.jpeg";
import _37 from "./assets/img/media/18-.jpeg";
import _38 from "./assets/img/media/19-.jpeg";
import _39 from "./assets/img/media/20-.jpeg";


export const files = {
"files": [
{
					"url": _0,
					"name": "0.82-"
				},
{
					"url": _1,
					"name": "0.83-"
				},
{
					"url": _2,
					"name": "0.84-"
				},
{
					"url": _3,
					"name": "0.85-"
				},
{
					"url": _4,
					"name": "0.86-"
				},
{
					"url": _5,
					"name": "0.87-"
				},
{
					"url": _6,
					"name": "0.88-"
				},
{
					"url": _7,
					"name": "0.89-"
				},
{
					"url": _8,
					"name": "0.90-"
				},
{
					"url": _9,
					"name": "0.91-"
				},
{
					"url": _10,
					"name": "0.92-"
				},
{
					"url": _11,
					"name": "0.93-"
				},
{
					"url": _12,
					"name": "0.94-"
				},
{
					"url": _13,
					"name": "0.95-"
				},
{
					"url": _14,
					"name": "0.96-"
				},
{
					"url": _15,
					"name": "0.97-"
				},
{
					"url": _16,
					"name": "0.98-"
				},
{
					"url": _17,
					"name": "0.99-"
				},
{
					"url": _18,
					"name": "profil-marion"
				},
{
					"url": _19,
					"name": "bio-portrait"
				},
{
					"url": _20,
					"name": "2.5-bio-portrait"
				},
{
					"url": _21,
					"name": "contact"
				},
{
					"url": _22,
					"name": "hero"
				},
{
					"url": _23,
					"name": "bio-banner"
				},
{
					"url": _24,
					"name": "marion-nabil"
				},
{
					"url": _25,
					"name": "marion-soliste"
				},
{
					"url": _26,
					"name": "news"
				},
{
					"url": _27,
					"name": "bonnelie"
				},
{
					"url": _28,
					"name": "9.1-bonnelie"
				},
{
					"url": _29,
					"name": "bonnelie"
				},
{
					"url": _30,
					"name": ""
				},
{
					"url": _31,
					"name": ""
				},
{
					"url": _32,
					"name": ""
				},
{
					"url": _33,
					"name": ""
				},
{
					"url": _34,
					"name": ""
				},
{
					"url": _35,
					"name": ""
				},
{
					"url": _36,
					"name": ""
				},
{
					"url": _37,
					"name": ""
				},
{
					"url": _38,
					"name": ""
				},
{
					"url": _39,
					"name": ""
				},

],
};