export const studiedPieces: string[] = [
    "Carmen de Bizet, airs et ensembles ( chanté avec orchestre et piano en version réduite)",
    "⁠Concepcion, L’heure Espagnole de Ravel, (rôle entier, cover de Stéphanie d’Oustrac à l’académie de l’opéra Comique production 23/24 dir. Louis Langree, mise en scène Guillaume Gallienne)",
    "⁠L’Enchanteresse, Didon et Enee, Purcell (rôle entier sur scène avec orchestre, dir. Leonardo Garcia Alarcon, mise en scène Marc Laîné, coproduction CNSM et Philharmonie) ",
    "⁠Siebel, Faust, Gounod, extraits et air avec orchestre (dir JP Sarcos, orchestre le Palais Royal)",
    "⁠Stephano, Roméo et Juliette de Gounod (rôle entier avec orchestre de chambre dir. Alexandra Cravero et mise en scène Chloé Ducray)",
    "⁠Sesto, La Clemenza di Tito de Mozart, rôle entier avec orchestre (Orchestre Fuoco dir. David Stern, mise en scène Héloïse Serazin, Opera de Massy)",
    "⁠Dorabella, Cosi fan Tutte de Mozart avec piano et orchestre de chambre en version réduite ",
    "⁠Lola, Cavalleria Rusticana de Mascagni, avec orchestre (dir. Giuseppe Grazioli, mise en scène Nicola Berloffa) Opera de St Étienne ",
    "⁠Dinah, Trouble in Tahiti, Bernstein, avec orchestre (Ondif, dir. David Stern, Philharmonie)",
    "⁠Mme de la Haltière, Cendrillon de Massenet, rôle entier étudié au CNSM ",
    "⁠Ariadna auf Naxos de Strauss, rôle de Komponist (rôle entier étudié au CNSM)",
    "⁠Ottavia,  ⁠L’Incoronazione di Poppea de Monterverdi, Ottavia (airs seuls étudiés « Disprezzata Regina », « Addio Roma » )",
    "⁠Armide, Lully, airs étudiés ",
    "⁠Giovanna Seymour, Anna Bolena de Donizetti, air étudié ",
    "⁠Charlotte, Werther De Massenet, airs étudiés ",
    "⁠La Périchole d’Offenbach, airs étudiés ",
    "⁠Shéhérazade de Ravel avec orchestre symphonique (dir. Quentin Hindley, orchestre Les clés d’Euphonia)",
    "⁠extraits des nuits d’été de Berlioz avec orchestre symphonique (orchestre de Pau pays de Béarn, dir. Fayçal Karoui)",
    "⁠Les Madécasses de Ravel avec l’orchestre de Chambre de Paris",
    "⁠Le Paen, extrait des Histoires Naturelles de Ravel avec l’Orchestre de chambre de Paris (dir. louis Langrée)  ",
    "⁠Le poème de l’amour et de la mer, Chausson avec piano ",
    "⁠Les Banalités de Poulenc avec piano ",
    "⁠les Frauenliebe und Leben de Schumann avec piano",
    "⁠les Chansons de Bilitis de Debussy avec piano ",
    "⁠Cinco Canciones negras de Montsalvatge avec piano ",
    "⁠Canciones Clasicas españolas de Obradors avec piano ",
    "⁠Canciones Castellanas de Guridi avec piano ",
    "Requiem de Mozart avec orchestre ",
    "⁠Erbarme dich de Bach avec orchestre ",
    "⁠Requiem de Duruflé",
]